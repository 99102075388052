/* eslint-disable */
import * as vueCarousel from 'vue-carousel';

import { Component } from '../';
import { MinimalComponent } from '../Base/MinimalComponent';
import { VueClass } from '../Base/Base';

export type CarouselComponentOptions = {
  props: Partial<vueCarousel.ICarousel>;
  children: {};
  exposed: {};
};

export type SlideComponentOptions = {
  props: Partial<vueCarousel.ISlide>;
  children: {};
  exposed: {};
};

// export const Carousel: VueClass<BaseComponent<CarouselComponentOptions, any>> = vueCarousel.Carousel as any as VueClass<BaseComponent<CarouselComponentOptions, unknown>>;
// export const Slide: VueClass<BaseComponent<SlideComponentOptions, any>> = vueCarousel.Slide as any as VueClass<BaseComponent<SlideComponentOptions, unknown>>;
// export const VueCarousel = vueCarousel.VueCarousel;


@Component({
  mixins: [vueCarousel.Carousel as unknown as VueClass<MinimalComponent<CarouselComponentOptions, any>>],
})
export class Carousel extends MinimalComponent<CarouselComponentOptions, any> {
  // Do nothing new other then the parent
}

@Component({
  mixins: [vueCarousel.Slide as unknown as VueClass<MinimalComponent<CarouselComponentOptions, any>>],
})
export class Slide extends MinimalComponent<CarouselComponentOptions, any> {
  // Do nothing new other then the parent
}

@Component({
  mixins: [vueCarousel.Carousel as unknown as VueClass<MinimalComponent<CarouselComponentOptions, any>>],
})
export default class VueCarousel extends MinimalComponent<CarouselComponentOptions, any> {
  // Do nothing new other then the parent
}

