import Vue from 'vue';

import { IComponentOptions, IComponentBaseAttributes } from './Base';

export class MinimalComponent<ComponentOptions extends IComponentOptions, E extends { [p: string]: any }> extends Vue {
  /**
   * Public JSX component API keeper
   */
  propTypes: ComponentOptions['props'] & IComponentBaseAttributes & Record<any, any>;

  get extended() {
    return (this as any) as Vue & E;
  }
}
